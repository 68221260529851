function addGourmet720Leaderboard(div) {
    if (div) {
        const outerDiv = document.createElement("div");
        outerDiv.classList.add("freshy-gourmet-outer-wrapper");

        const firstDiv = document.createElement("div");
        firstDiv.id = "ga_22574294";

        const secondDiv = document.createElement("div");
        secondDiv.setAttribute(
            "style",
            "text-align:right; width:300px; padding:5px 0;"
        );

        const imgDiv = document.createElement("img");
        imgDiv.setAttribute(
            "src",
            "https://bcdn.grmtas.com/images/gourmetads-logo.jpg"
        );
        imgDiv.setAttribute("alt", "logo");
        imgDiv.setAttribute("style", "float:right; border:none;");

        const innerDiv = document.createElement("div");
        innerDiv.setAttribute(
            "style",
            "width:auto; padding:4px 5px 0 0; float:right; display:inline-block; font-family:Verdana, Geneva, sans-serif; font-size:11px; color:#333;"
        );

        const aTag = document.createElement("a");
        aTag.setAttribute("href", "https://www.gourmetads.com");
        aTag.setAttribute("target", "_blank");
        aTag.setAttribute("title", "Food Advertisements");
        aTag.setAttribute("style", "text-decoration:none; color:#333;");
        aTag.innerText = "Food Advertisement";

        innerDiv.appendChild(aTag);
        innerDiv.innerHTML += " by";

        secondDiv.appendChild(imgDiv);
        secondDiv.appendChild(innerDiv);

        outerDiv.appendChild(firstDiv);
        outerDiv.appendChild(secondDiv);

        try {
            div.appendChild(outerDiv);

            return outerDiv;
        } catch (e) {
            console.error("Error loading Gormet - ", e);
        }
    } else return null;
}

function gourmet320Inline(div) {
    if (div) {
        const outerDiv = document.createElement("div");
        outerDiv.classList.add("freshy-gourmet-outer-wrapper");

        const firstDiv = document.createElement("div");
        firstDiv.id = "ga_22574555";

        const secondDiv = document.createElement("div");
        secondDiv.setAttribute(
            "style",
            "text-align:right; width:300px; padding:5px 0;"
        );

        const imgDiv = document.createElement("img");
        imgDiv.setAttribute(
            "src",
            "https://bcdn.grmtas.com/images/gourmetads-logo.jpg"
        );
        imgDiv.setAttribute("alt", "logo");
        imgDiv.setAttribute("style", "float:right; border:none;");

        const innerDiv = document.createElement("div");
        innerDiv.setAttribute(
            "style",
            "width:auto; padding:4px 5px 0 0; float:right; display:inline-block; font-family:Verdana, Geneva, sans-serif; font-size:11px; color:#333;"
        );

        const aTag = document.createElement("a");
        aTag.setAttribute("href", "https://www.gourmetads.com");
        aTag.setAttribute("target", "_blank");
        aTag.setAttribute("title", "Food Advertisements");
        aTag.setAttribute("style", "text-decoration:none; color:#333;");
        aTag.innerText = "Food Advertisement";

        innerDiv.appendChild(aTag);
        innerDiv.innerText += " by";

        secondDiv.appendChild(imgDiv);
        secondDiv.appendChild(innerDiv);

        outerDiv.appendChild(firstDiv);
        outerDiv.appendChild(secondDiv);

        try {
            div.appendChild(outerDiv);
            return true;
        } catch (e) {
            console.error("Error loading Gormet - ", e);
        }
    } else return null;
}

function addGourmetScript() {
    if (window?.gourmetLoaded !== undefined) return;
    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("src", "//bcdn.grmtas.com/pub/ga_pub_14153.js");
    script.setAttribute("defer", "");

    document.head.appendChild(script);
    window.gourmetLoaded = true;
}

function refreshLeaderboard() {
    // ga_22587010
    if (window?.apntag?.refresh !== undefined) {
        window.apntag.refresh(["ga_22587010"]);
    }
}

function refresh720() {
    // ga_22574294
    if (window?.apntag?.refresh !== undefined) {
        window.apntag.refresh(["ga_22574294"]);
    }
}

function refresh300() {
    // ga_22574555
    if (window?.apntag?.refresh !== undefined) {
        window.apntag.refresh(["ga_22574555"]);
    }
}

function refreshAll() {
    if (window?.apntag?.refresh !== undefined) {
        window.apntag.refresh();
    }
}

function resetBannerPosition(resetPosition = false) {
    const currentBanner = document.querySelector("#ga_sticky_bottom");
    const appRoot = document.querySelector("#app_root");

    if (currentBanner && appRoot) {
        if (resetPosition) currentBanner.style.bottom = "0px";

        const currentFullPosition = window.scrollY + window.innerHeight;
        const documentHeight = appRoot.offsetHeight;
        const FOOTER_OFFSET = 125;
        const offsetLimit = documentHeight - FOOTER_OFFSET;
        if (currentFullPosition >= offsetLimit) {
            // footer is visible
            const offsetBottom = currentFullPosition - offsetLimit;
            currentBanner.style.bottom = `${offsetBottom}px`;
        } else {
            // footer is not visible
            currentBanner.style.bottom = `0px`;
        }
    }
}

export {
    addGourmet720Leaderboard,
    gourmet320Inline,
    addGourmetScript,
    refresh300,
    refresh720,
    refreshLeaderboard,
    refreshAll,
    resetBannerPosition,
};

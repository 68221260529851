// vue.config.js
module.exports = {
    devServer: {
        clientLogLevel: "error",
        noInfo: true,
        overlay: true,
        proxy: {
          "/core/v2/search":{
            target: "https://staging-cooking.freshy.com",
            changeOrigin: true,
          },
          "/core/v2/complete":{
            target: "https://staging-cooking.freshy.com",
            changeOrigin: true,
          },
          "/core/v2/recommend":{
            target: "https://staging-cooking.freshy.com",
            changeOrigin: true,
          },
          "/api/v1/recipes":{
            target: "https://staging-cooking.freshy.com",
            changeOrigin: true,
          },
          "/api/get.php": {
            target: "https://xml.freshforever.services",
            changeOrigin: true,
          },
          "/y-feeds/news": {
            target: "https://staging-cooking.freshy.com",
            changeOrigin: true,
          },
          "/ads": {
            target: "https://staging-cooking.freshy.com",
            changeOrigin: true,
          },
          "/ideas": {
            target: "https://staging-cooking.freshy.com",
            changeOrigin: true,
          },
          "/extensions/adtrack.php": {
            target: "https://staging-cooking.freshy.com",
            changeOrigin: true,
          },
        }
    },
    chainWebpack: config => {
      config.plugins.delete('case-sensitive-paths')
    },
    assetsDir: './public/assets',
    publicPath: "/cooking/",
    pages: {
      index: {
        entry: 'src/main.js',
        title: 'Freshy Recipes'
      }
    }
}
import { addGourmetScript } from "../../gourmet";

export default {
    data() {
        return {};
    },
    methods: {
        loadGourmet() {
            this.$nextTick(() => {
                addGourmetScript();
            });
        },
    },
};

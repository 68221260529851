import { database } from "./firebase";
import { store } from "../store/store";

export const USER_TYPE = {
    premium: "premium",
    extension: "extension",
    free: "free",
};

/**
 * User class
 * Must be created through the initUsermethod, which is asyncronous (i.e. User.initUser(authUserData))
 */
export class User {
    constructor() {
        this.uid = null;
        this.email = null;
        this.emailVerified = null;
        this.accountCreated = null;
        this.lastLogin = null;
        this.isNewUser = null;

        // These must be initialized from db and changed through functions
        this.accountType = null;
        this.recipeList = null;
        this.firstName = null;
        this.lastName = null;

        // Display name comes from google auth credentials
        this.displayName;

        // will contain unsubscribe function when listener starts
        this._docListener = null;
        this._visibilityListener = null;

        // If an error is called, will be placed here
        this._dbErrors = null;
    }

    get premium() {
        if (this.accountType == USER_TYPE.premium) return true;
        if (this.doTrial && this.accountCreated) {
            if (Date.now() - this.accountCreated < this.trialLength)
                return true;
            return false;
        }
        return false;
    }

    get remainingTrialTime() {
        if (this.doTrial && this.accountCreated && this.trialLength) {
            const remaining =
                this.trialLength - (Date.now() - this.accountCreated);
            if (remaining > 0) return remaining;
            return 0;
        }
        return 0;
    }

    get trialActive() {
        if (this.remainingTrialTime > 0) return true;
        return false;
    }

    get remainingTrialDays() {
        const DAY = 86400000;
        const remaining = this.remainingTrialTime;
        if (remaining > 0) {
            return Math.ceil(remaining / DAY);
        }
        return 0;
    }

    get fullName() {
        if (this.firstName && this.lastName)
            return `${this.firstName} ${this.lastName}`;
        if (this.displayName) return this.displayName;
        if (this.lastName) return this.lastName;
        if (this.firstName) return this.firstName;
        return null;
    }

    get shortName() {
        if (this.firstName) return this.firstName;
        if (this.lastName) return this.lastName;
        if (this.displayName) return this.displayName;
        return null;
    }

    get hasRecentlyLoggedIn() {
        if (this.lastLogin) {
            const MAX_TIME = 300000; // 5 minutes
            return Date.now() - this.lastLogin < MAX_TIME;
        }
        return false;
    }

    async addRecipe(uuid, url, recipeData) {
        if (!this.recipeList) this.recipeList = {};
        if (this.recipeList[uuid]) {
            const e = new Error(
                "You already have this recipe saved in your recipe book."
            );
            e.name = "Duplicate Recipe";
            throw e;
        }
        let newRecipe = {};
        if (uuid && url && recipeData) {
            newRecipe = {
                dataStatus: true,
                title: recipeData.title,
                image: recipeData.image,
                source: url,
                addDate: Date.now(),
                calories: recipeData.nutrients.calories || null,
                carbohydrates:
                    recipeData.nutrients.carbohydrates ||
                    recipeData.nutrients.carbohydrateContent ||
                    null,
                cooktime: recipeData.total_time || null,
            };
        } else if (!uuid && url && !recipeData) {
            newRecipe = {
                dataStatus: false,
                source: url,
            };
            uuid = url;
        }

        this.recipeList[uuid] = newRecipe;
        this.recipeList = { ...this.recipeList };
        return await this.updateUserDBObj();
    }

    async batchAddRecipes(recipes) {
        if (!this.recipeList) this.recipeList = {};
        this.recipeList = {
            ...recipes,
            ...this.recipeList,
        };
        return await this.updateUserDBObj();
    }

    async removeRecipe(uuid) {
        if (this.recipeList[uuid]) delete this.recipeList[uuid];
        this.recipeList = { ...this.recipeList };
        return await this.updateUserDBObj();
    }

    async changeAccountType(type) {
        if (USER_TYPE[type]) {
            this.accountType = USER_TYPE[type];
            return await this.updateUserDBObj();
        }
    }

    async deleteUserDBObj() {
        try {
            if (this.uid) {
                await database
                    .collection("users")
                    .doc(this.uid)
                    .delete();
                return { success: true };
            }
            return { success: false, reason: "NO_CURRENT_USER" };
        } catch (e) {
            console.error("Error deleting User obj from DB", e);
            return { success: false, reason: "UNKNOWN_ERROR", error: e };
        }
    }

    async updateUserDBObj() {
        const data = {
            recipeList: this.recipeList,
            firstName: this.firstName,
            lastName: this.lastName,
            accountType: this.accountType,
            emailVerified: this.emailVerified,
        };

        return await database
            .collection("users")
            .doc(this.uid)
            .set(data);
    }

    onVisibilityChange() {
        // Function to remove listener from not visible tab/window to prevent uneccessary db calls
        try {
            if (store.state.user.user) {
                if (document.visibilityState != "visible") {
                    store.state.user.user.removeDBListener();
                } else {
                    store.state.user.user.setDBListener();
                }
            }
        } catch (e) {
            console.error("Error setting onVisibilityChange", e);
        }
    }

    async setDBListener() {
        // Remove doc listener whenever this function is called to make sure there is only one running at a time
        document.removeEventListener(
            "visibilitychange",
            this.onVisibilityChange
        );
        this._visibilityListener = null;

        if (this.uid && !this._docListener) {
            const unsubscribe = database
                .collection("users")
                .doc(this.uid)
                .onSnapshot(doc => {
                    if (!this._docListener) this._docListener = unsubscribe;
                    const data = doc.data();
                    for (const i in data) {
                        this[i] = data[i];
                    }
                });
            if (!this._visibilityListener) {
                document.addEventListener(
                    "visibilitychange",
                    this.onVisibilityChange
                );
                this._visibilityListener = true;
            }

            // Listen for this._docListener to be set

            const check = await new Promise(resolve => {
                let timeout = 10000;
                const interval = setInterval(() => {
                    if (this._docListener) {
                        resolve(true);
                        clearInterval(interval);
                    } else if (timeout < 0) {
                        resolve(false);
                        clearInterval(interval);
                    } else timeout -= 50;
                }, 50);
            }).catch(e => {
                console.error("Error checking for docListener - ", e);
                return false;
            });

            return check;
        }
        return null;
    }

    removeDBListener() {
        if (this._docListener) {
            this._docListener();
            this._docListener = null;
        }
    }

    async initUser(authUserData, custData = null, isNewUser = false) {
        try {
            const { uid } = authUserData;
            if (uid) {
                // Init with user data first
                this.uid = uid;
                this.email = authUserData.email;
                this.emailVerified = authUserData.emailVerified;
                this.displayName = authUserData.displayName;
                if (authUserData.metadata) {
                    this.accountCreated = parseInt(authUserData.metadata.a);
                    this.lastLogin = parseInt(authUserData.metadata.b);
                }

                // Check if user is in db
                // const docRef = database.collection("users").doc(this.uid)
                if (custData) {
                    // User doesn't exist yet or we have data to update, so use custData to create or update entry
                    // With merge set, if doc exists and only some custData is available, will only overwrite supplied fields from custData

                    for (const i in custData) {
                        this[i] = custData[i];
                    }
                    await this.updateUserDBObj();
                }
                // Get remaining info from DB
                const res = await this.setDBListener();

                // Check email verification edge case catch
                if (res) {
                    if (!this.emailVerified && authUserData.emailVerified) {
                        this.emailVerified = authUserData.emailVerified;
                        this.updateUserDBObj();
                    }
                }

                // set if new user
                if (isNewUser) this.isNewUser = true;
                else this.isNewUser = false;

                localStorage.setItem("displayName", this.shortName);

                return res;
            }
        } catch (e) {
            console.error("Error initializing User - ", e);
            return false;
        }
    }
}

<template>
    <div class="offerCard recipeCardRoot">
        <a
            :href="data.link"
            target="_blank"
            v-if="data && data.link"
            class="recipe-card-wrapper p-grid p-shadow-1"
            @click="onClick()"
        >
            <div
                v-if="data.image"
                class="recipe-card-left"
                :class="{
                    'p-col-12': data.image,
                    'p-d-none': !data.image,
                }"
            >
                <img class="recipe-card-img" :src="data.image" />
            </div>
            <div
                class="recipe-card-right p-col-12"
                :class="{
                    'p-col-12': data.image,
                    'p-col-12': !data.image,
                }"
            >
                <div class="recipe-card-data">
                    <div
                        v-if="formattedTitle"
                        class="recipe-card-title recipe-card-data"
                    >
                        <div class="offerTitle">
                            <span v-snip="2" :title="formattedTitle">{{
                                formattedTitle
                            }}</span>
                        </div>
                    </div>
                    <div
                        style="display: none;"
                        v-if="data.description"
                        class="recipe-card-meta"
                    >
                        <span> {{ data.description }}</span>
                    </div>
                </div>
                <div class="offerCardTag">
                    Sponsored Ad
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import { getDefaultAdnetAds } from "../../fetchFunctions";

export default {
    name: "recipe-card",
    data() {
        return {
            data: null,
        };
    },
    computed: {
        formattedTitle() {
            if (this.data && this.data.title) return this.data.title;
            return null;
        },
    },
    async mounted() {
        const ads = await getDefaultAdnetAds();
        if (ads && ads.data && ads.data[0]) {
            this.data = ads.data[0];
            this.$trTracking.fireEvent({
                action: "impression",
                option: this.$route.name,
                feed: "adnet",
                ads: 1,
            });
        } else {
            this.$emit("error");
        }
    },
    methods: {
        onClick() {
            this.$emit("onClick", this.data);
            const event = {
                action: "click",
                option: this.$route.name,
                feed: "adnet",
            };
            if (
                this.data &&
                this.data.additionalInfo &&
                this.data.additionalInfo.bid
            )
                event.rpc = this.data.additionalInfo.bid;
            this.$trTracking.fireEvent(event);
        },
    },
};
</script>

<style scoped src="./recipeCard.css"></style>

<template>
    <div class="recipe-list-root">
        <DataTable
            v-if="recipes"
            :value="recipes"
            :rowClass="isSelected"
            scrollHeight="701px"
            responsiveLayout="scroll"
            v-model:filters="filters"
            filterDisplay="row"
            @rowClick="onRowSelect"
            v-model:selection="currentSelectedProduct"
            selectionMode="single"
            :scrollable="true"
        >
            <Column
                header="Recipes"
                field="title"
                :filterMatchModeOptions="matchModeOptions"
            >
                <template #body="{data}">
                    <div class="recipe-list-item p-grid">
                        <div
                            v-if="data.image"
                            class="recipe-list-img-wrapper p-col-3 p-d-none p-d-md-block"
                            :class="{
                                'p-col-3': data.image,
                                'p-d-none': !data.image,
                            }"
                        >
                            <img class="recipe-list-img" :src="data.image" />
                        </div>
                        <div
                            class="recipe-list-title-wrapper p-col-12 p-md-7 p-m-0 p-pr-4"
                            :class="{
                                'p-md-7': data.image,
                                'p-md-12': !data.image,
                            }"
                        >
                            <span v-if="data.title" class="recipe-list-title">{{
                                data.title
                            }}</span>
                            <span
                                v-else-if="data.source"
                                class="recipe-list-title"
                                >{{ data.source }}</span
                            >
                        </div>

                        <Button
                            v-if="currentSelectedProduct === data"
                            class="p-button-outlined p-button-secondary p-col-2"
                            @click="onRemoveRecipe(data)"
                            icon="pi pi-trash"
                        />
                    </div>
                </template>
                <template #filter="{filterModel,filterCallback}">
                    <span class="p-input-icon-left"
                        ><i class="pi pi-search"/>
                        <InputText
                            type="text"
                            v-model="filterModel.value"
                            @input="filterCallback()"
                            class="p-column-filter"
                            :placeholder="`Search Your Recipes`"
                    /></span>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import { FilterMatchMode } from "primevue/api";
import Button from "primevue/button";
import recipeMixin from "../componentMixins/recipeMixin";

export default {
    props: ["recipes", "defaultSelected"],
    mixins: [recipeMixin],
    components: {
        DataTable,
        Column,
        InputText,
        Button,
    },
    data() {
        return {
            filters: {
                title: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            matchModeOptions: [
                { label: "Contains", value: FilterMatchMode.CONTAINS },
            ],
            selectedUUID: null,
            currentSelectedProduct: null,
        };
    },
    mounted() {
        if (this.defaultSelected)
            this.currentSelectedProduct = this.defaultSelected;
    },
    watch: {
        defaultSelected() {
            if (this.defaultSelected)
                this.currentSelectedProduct = this.defaultSelected;
        },
    },
    methods: {
        onRecipeClick(recipe, index) {
            this.selectedUUID = recipe.uuid;
            this.$emit("onRecipeClick", recipe, index);
        },
        isSelected(row) {
            if (row && row.uuid == this.selectedUUID)
                return "recipe-list-item-selected";
            return "";
        },
        onRowSelect(evt) {
            if (evt.data && evt.data.uuid)
                this.onRecipeClick(evt.data, evt.index);
        },
        onRemoveRecipe(recipe) {
            this.onRemoveRecipeClick(recipe.uuid);
        },
    },
};
</script>

<style scoped src="./recipeList.css"></style>

<template>
    <div class="gormet-wrapper-720">
        <div id="ga_22574294"></div>
        <!--
        <div style="text-align:right; padding:5px 0;">
            <div style="width:auto; padding:4px 5px 0 0; display:inline-block; font-family:Verdana, Geneva, sans-serif; font-size:11px; color:#333;">
                <a href="https://www.gourmetads.com" target="_blank" title="Food Advertisements" style="text-decoration:none; color:#333;">Food Advertisements</a> by
            </div>
            <img src="https://bcdn.grmtas.com/images/gourmetads-logo.jpg" alt="logo" style="float:right; border:none; width: 97px" />
        </div>
        -->
    </div>
</template>

<script>
export default {
    name: "banner720",
};
</script>

<style>
.gormet-wrapper-720 {
    margin: 0px auto;
    text-align: center;
}
</style>

import { createWebHistory, createRouter } from "vue-router";
import { store } from "./store/store";
import firebase from "./auth/firebase";
import Home from "./pages/home/Home.vue";
import Search from "./pages/search/Search.vue";
import Recipe from "./pages/recipe/Recipe.vue";
import RecipeBook from "./pages/recipebook/RecipeBook.vue";
import Login from "./pages/auth/Auth.vue";
import Management from "./pages/accountManagement/AccountManagement.vue";
import NotFound from "./pages/notFound/NotFound.vue";
import { refreshAll, resetBannerPosition } from "./gourmet";
import { publicPath } from "../vue.config"

// FOOTER PAGES
import Privacy from "./pages/footerPages/PrivacyPolicy.vue";
import Contact from "./pages/footerPages/ContactUs.vue";
import Tos from "./pages/footerPages/Tos.vue";
import Uninstall from "./pages/footerPages/Uninstall.vue";

export const BASE_TITLE = "Freshy Recipes - ";

const routes = [
    {
        path: "/cooking/",
        name: "Home",
        component: Home,
        beforeEnter: (to, from, next) => {
            document.title = `${BASE_TITLE}Home`;
            next();
        },
    },
    {
        path: "/cooking/newtab",
        name: "Newtab",
        component: Home,
        beforeEnter: (to, from, next) => {
            document.title = `${BASE_TITLE}Newtab`;
            next();
        },
    },
    {
        path: "/cooking/search",
        name: "Search",
        component: Search,
        beforeEnter: (to, from, next) => {
            document.title = `${BASE_TITLE}Search`;
            next();
        },
    },
    {
        path: "/cooking/recipe",
        redirect: { name: "Home" },
    },
    {
        path: "/cooking/recipe/:uuid",
        name: "Recipe",
        component: Recipe,
        props: true,
    },
    {
        path: "/cooking/recipebook",
        name: "RecipeBook",
        component: RecipeBook,
        beforeEnter: (to, from, next) => {
            document.title = `${BASE_TITLE}Recipe Book`;
            next();
        },
        props: true,
    },
    {
        path: "/cooking/auth",
        name: "Login",
        component: Login,
        beforeEnter: (to, from, next) => {
            document.title = `${BASE_TITLE}Login`;
            next();
        },
        props: route => ({
            ...route.params,
        }),
    },
    {
        path: "/cooking/preferences",
        name: "Preferences",
        component: Management,
        beforeEnter: (to, from, next) => {
            document.title = `${BASE_TITLE}Account Preferences`;
            next();
        },
    },
    {
        path: "/cooking/privacy-policy",
        name: "PrivacyPolicy",
        component: Privacy,
        beforeEnter: (to, from, next) => {
            document.title = `${BASE_TITLE}Privacy Policy`;
            next();
        },
    },
    {
        path: "/cooking/uninstall",
        name: "Uninstall",
        component: Uninstall,
        beforeEnter: (to, from, next) => {
            document.title = `${BASE_TITLE}Uninstall`;
            next();
        },
    },
    {
        path: "/cooking/terms-of-service",
        name: "Tos",
        component: Tos,
        beforeEnter: (to, from, next) => {
            document.title = `${BASE_TITLE}Terms of Service`;
            next();
        },
    },
    {
        path: "/cooking/contact-us",
        name: "ContactUs",
        component: Contact,
        beforeEnter: (to, from, next) => {
            document.title = `${BASE_TITLE}Contact Us`;
            next();
        },
    },
    {
        path: "/cooking/404",
        name: "NotFound",
        component: NotFound,
        beforeEnter: (to, from, next) => {
            document.title = `${BASE_TITLE}404 Not Found`;
            next();
        },
    },
    {
        // NOT FOUND redirect
        // MUST ALWAYS BE LAST
        path: "/:catchAll(.*)",
        redirect: { name: "NotFound" },
        beforeEnter: (to, from, next) => {
            document.title = `${BASE_TITLE} REDIRECT-TO: 404 Not Found`;
            next();
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    base: "/cooking/",
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return {
                selector: to.hash,
            };
        }
        return { left: 0, top: 0 };
    },
});

router.beforeEach(async (to, from, next) => {
    // reload user and check feeds
    await Promise.all([
        store.dispatch("reloadUser"),
        store.dispatch("loadYFeed"),
    ]);

    const { user } = store.state.user;

    if (!user) {
        const currUser = await firebase.auth().currentUser;
        if (currUser) store.commit("setUser", currUser);
        else {
            // Will redirect to login flow here as long as to address isn't login window.
            // Must remember to store current target as prop for router during flow.
        }
    }
    next();
});

router.afterEach(to => {
    if (to.name.toLocaleLowerCase() !== "newtab");
    refreshAll();
    firebase.analytics().logEvent("page_view");

    // Reset Banner Ad Height
    resetBannerPosition();
});

export default router;

<template>
    <div class="row">
        <div
            id="auth-menu-root"
            :class="isNewtab ? 'p-col-4 f-right' : ''"
            :key="menuKey"
        >
            <Toast />
            <OverlayAuthWindow
                v-if="showAuthWindow"
                :hidePromo="hidePromo"
                @closed="hideDialog"
                @login="onLogin"
                @logout="onLogout"
                :mode="mode"
            />
            <RecipeAdd @savedRecipe="showAddedRecipe" ref="recipeAdd" />
            <div
                id="user-menu-wrapper"
                class="noUserSelect p-d-none p-d-lg-flex"
                type="button"
                label="Toggle"
                aria-haspopup="true"
                aria-controls="user-menu"
            >
                <router-link :to="`/cooking/auth?continue=%2Fcooking%2Frecipebook`">
                    <button
                        v-if="!isNewtab"
                        class="flexmargin p-button p-component  book-auth"
                        @click.prevent="recipeBookClick"
                    >
                        <span class="p-button-label">View Recipe Book</span>
                    </button>
                </router-link>
                <span class="flexmargin blog-auth"
                    ><a href="/ideas/">Recipe Ideas</a></span
                >
                <router-link :to="`/cooking/auth`">
                    <span
                        class="flexmargin login-auth"
                        v-if="!loggedIn"
                        @click.prevent="onLoginClick"
                        >Log in</span
                    >
                </router-link>
                <router-link :to="`/cooking/auth`">
                    <span
                        class="flexmargin signup-auth"
                        v-if="!loggedIn"
                        @click.prevent="onSignupClick"
                        >Sign Up</span
                    >
                    <span
                        class="name-auth"
                        v-else
                        @click.prevent="toggleMenuLogin"
                        >Hello {{ formattedShortName }}</span
                    >
                </router-link>
                <span
                    id="addRecipeButtonHeader"
                    class="f-right"
                    v-if="canShowAddNewButton"
                >
                    <Button
                        id="addRecipebtn"
                        icon="pi pi-plus-circle"
                        class="p-button-header f-right"
                        label="Add New Recipe"
                        @click="onAddRecipeBtnClick"
                    />
                </span>
            </div>
            <Menu id="user-menu" ref="userMenu" :model="items" :popup="true" />
            <div id="user-menu-small-wrapper" class="p-d-flex p-d-lg-none">
                <i
                    class="pi pi-bars user-menu-small-icon"
                    aria-controls="user-menu-small"
                    aria-haspopup="true"
                    @click="toggleSmallMenu"
                ></i>
            </div>
            <Menu
                id="user-menu-small"
                ref="userMenuSmall"
                :model="smallMenuItems"
                :popup="true"
            />
        </div>
    </div>
</template>

<script>
import Menu from "primevue/menu";
import Toast from "primevue/toast";
import { mapActions } from "vuex";
import Button from "primevue/button";
import OverlayAuthWindow from "../authWindow/OverlayAuthWindow";
import userMixin from "../../../store/mixins/user";
import extensionMixin from "../../componentMixins/extensionMixin";
import newtabMixin from "../../componentMixins/newtabMixin";
import RecipeAdd from "../../recipeAdd/RecipeAdd";

export default {
    name: "auth-menu",
    mixins: [userMixin, extensionMixin, newtabMixin],
    components: {
        Button,
        RecipeAdd,
        Menu,
        Toast,
        OverlayAuthWindow,
    },
    data() {
        return {
            canShowAddNewButton: false,
            showAuthWindow: false,
            hidePromo: true,
            followRoute: false,
            menuKey: 0,
            items: [
                {
                    label: "Account Preferences",
                    // icon: 'pi pi-refresh',
                    to: "/cooking/preferences",
                    visible: () => this.loggedIn,
                },
                {
                    label: "Logout",
                    // icon: 'pi pi-times',
                    command: async () => {
                        await this.logout();
                        this.menuKey++;
                        if (await this.isExtensionUser()) {
                            this.postMessageToExtension("logout");
                        }
                    },
                    visible: () => this.loggedIn,
                },
            ],
            smallMenuItems: [
                {
                    label: "My Recipe Book",
                    command: () => {
                        this.recipeBookClick();
                    },
                },
                {
                    label: "Login",
                    visible: () => !this.loggedIn,
                    command: () => {
                        this.onLoginClick();
                    },
                },
                {
                    label: "Signup",
                    visible: () => !this.loggedIn,
                    command: () => {
                        this.onSignupClick();
                    },
                },
                {
                    label: "Account Preferences",
                    // icon: 'pi pi-refresh',
                    to: "/cooking/preferences",
                    visible: () => this.loggedIn,
                },
                {
                    label: `Logout ${
                        this.activeUser && this.activeUser.fullName
                            ? `of ${this.activeUser.fullName}`
                            : ""
                    }`,
                    // icon: 'pi pi-times',
                    command: async () => {
                        await this.logout();
                        this.menuKey++;
                    },
                    visible: () => this.loggedIn,
                },
            ],
            mode: null,
        };
    },
    computed: {
        formattedShortName() {
            if (this.activeUser && this.activeUser.shortName) {
                if (this.activeUser.shortName.length > 12)
                    return `${this.activeUser.shortName.substring(0, 12)}...`;
                return this.activeUser.shortName;
            }
            return "";
        },
    },
    watch: {
        activeUser() {
            this.menuKey++;
            if (this.showAuthWindow) this.showAuthWindow = false;
        },
        $route() {
            this.show = false;
            this.canShowAddNewButton = this.isNewtab;
        },
    },
    created() {},
    mounted() {
        if (!this.loggedIn) {
            this.fireGoogleOneTapPrompt();
        }
        this.canShowAddNewButton = this.isNewtab;
    },
    methods: {
        ...mapActions(["logout"]),
        toggleSmallMenu(event) {
            if (this.$refs.userMenuSmall)
                this.$refs.userMenuSmall.toggle(event);
        },
        toggleMenuLogin(event) {
            if (!this.loggedIn) {
                this.mode = null;
                this.showAuthWindow = true;
            } else this.$refs.userMenu.toggle(event);
        },
        toggleMenuSignup(event) {
            if (!this.loggedIn) {
                this.mode = "createAccount";
                this.showAuthWindow = true;
            } else this.$refs.userMenu.toggle(event);
        },
        onAuthWindowSuccess() {
            this.showAuthWindow = false;
        },
        onLogin() {
            this.onAuthWindowSuccess();
            if (this.followRoute) {
                this.$router.push({
                    name: this.followRoute,
                });
            }
        },
        onLogout() {
            this.onAuthWindowSuccess();
        },
        hideDialog(value) {
            if (!value) this.showAuthWindow = false;
            this.hidePromo = true;
        },
        recipeBookClick(hidePromo = false) {
            if (this.loggedIn) {
                this.$router.push({
                    name: "RecipeBook",
                });
            } else {
                this.followRoute = "RecipeBook";
                this.hidePromo = hidePromo || false;
                this.toggleMenuLogin();
            }
        },
        onLoginClick() {
            this.hidePromo = true;
            this.toggleMenuLogin();
        },
        onSignupClick() {
            this.hidePromo = true;
            this.toggleMenuSignup();
        },
        showAddedRecipe(recipe) {
            if (recipe.uuid) {
                this.$router.push({ path: `/recipe/${recipe.uuid}` });
            }
        },
        onAddRecipeBtnClick() {
            if (!this.activeUser) {
                this.showAuthWindow = true;
                this.hidePromo = false;
            } else {
                this.$refs.recipeAdd.show();
            }
        },
    },
};
</script>

<style src="./authMenu.css"></style>
